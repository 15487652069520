.r12k9g2u{display:block;padding-left:var(--element-spacing);padding-right:var(--element-spacing);}
.peinnp9{background-color:var(--gsu-primary);color:#fff;}
.s1w0qp4d{background-color:var(--gsu-secondary);color:#fff;}
.t1af2vcu{background-color:var(--gsu-tertiary10);}
.c2u4t1c{background-color:transparent;}
.w1hs29zy{background-color:#fff;}
.b7eza0n{background-color:var(--gsu-black);color:#fff;}
.gxh6ltx{background:url('/images/guide-page-header.png');background-size:cover;}
.s1oxgj0f{padding-top:var(--element-spacing);padding-bottom:var(--element-spacing);}
.sqvs8ws{padding-top:40px;padding-bottom:40px;}
.s1b53jux{padding-top:50px;padding-bottom:50px;}
.p1dwtsrq{position:fixed;width:100%;left:0;z-index:3;margin-top:-1px;}@media screen and (min-width:1280px){.p1dwtsrq{width:calc(100% - var(--desktop-nav-width));left:unset;}}
.p1rcz8tx{z-index:-1;pointer-events:none;opacity:0;}
